import jwt_decode from 'jwt-decode';

export const setToken = (token) => {
    localStorage.setItem('session-token', token);
}

export const setIsLogged = (status) => {
    localStorage.setItem('is-logged', status);
}

export const setUserDetails = (data) => {
    let userDetails = {
      id: data.id,
      full_name: data.full_name,
      degree: data.degree,
      degree_class: data.degree_class,
      degree_completed_date: data.degree_completed_date,
      email: data.email,
      cv_url: data.cv_url,
      profile_pic_url: data.profile_pic_url,
      contact_number: data.contact_number,
      address: data.address,
      reg_number_in_faculty: data.reg_number_in_faculty,
      theme: data.theme,
      passout_batch_id: data.passout_batch_id,
      passout_batch_name: data.passout_batch_name
    }
    localStorage.setItem('user-details', JSON.stringify(userDetails));
}

export const getUser = () => {
    return JSON.parse(localStorage.getItem('user-details'));
}

export const getIsLogged = () => {
    return localStorage.getItem('is-logged') || false;
}

export const checkLogin = () => {
    var jwt = window.localStorage.getItem('session-token');
    if (jwt) {
        var decoded = jwt_decode(jwt);
        if (Date.now() / 1000 > decoded.exp) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

export const logout = () => {
    localStorage.clear();
}
